// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-en-404-js": () => import("./../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-about-me-js": () => import("./../src/pages/en/about-me.js" /* webpackChunkName: "component---src-pages-en-about-me-js" */),
  "component---src-pages-en-history-js": () => import("./../src/pages/en/history.js" /* webpackChunkName: "component---src-pages-en-history-js" */),
  "component---src-pages-en-index-js": () => import("./../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-skills-js": () => import("./../src/pages/en/skills.js" /* webpackChunkName: "component---src-pages-en-skills-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ja-404-js": () => import("./../src/pages/ja/404.js" /* webpackChunkName: "component---src-pages-ja-404-js" */),
  "component---src-pages-ja-about-me-js": () => import("./../src/pages/ja/about-me.js" /* webpackChunkName: "component---src-pages-ja-about-me-js" */),
  "component---src-pages-ja-history-js": () => import("./../src/pages/ja/history.js" /* webpackChunkName: "component---src-pages-ja-history-js" */),
  "component---src-pages-ja-index-js": () => import("./../src/pages/ja/index.js" /* webpackChunkName: "component---src-pages-ja-index-js" */),
  "component---src-pages-ja-skills-js": () => import("./../src/pages/ja/skills.js" /* webpackChunkName: "component---src-pages-ja-skills-js" */),
  "component---src-templates-project-template-js": () => import("./../src/templates/projectTemplate.js" /* webpackChunkName: "component---src-templates-project-template-js" */)
}

